
import { Component, Prop, Vue } from "vue-property-decorator";
import { NextPlan } from "@/models/entities/next-plan.interface";
import { OrderingSubjectView } from "@/models/entities/ordering-subject-view.interface";
import { SubjectAndRole } from "@/models/entities/subject-role.interface";
import { orderingSubjectService } from "@/services/api/ordering-subject.service";

@Component
export default class OrderingSubjectList extends Vue {
  @Prop({ required: false, default: null, type: Array })
  private orderingSubjectView: OrderingSubjectView[];

  private subjectAndRole: SubjectAndRole = {
    roleDescription: "",
    totalSubjects: 1,
  };

  private nextPlan: NextPlan = {
    roleDescription: "",
    totalSubject: 1,
    roleID: 0,
  };

  async mounted(): Promise<void> {
    this.subjectAndRole = (
      await orderingSubjectService.GetRoleAndTotSubject()
    ).data;
    this.nextPlan = (
      await orderingSubjectService.GetNextPlan(
        this.subjectAndRole.totalSubjects
      )
    ).data;
    // this.nextPlan.roleDescription = upper(this.nextPlan.roleDescription);
  }

  private click(id: number): void {
    this.$emit("click", id);
  }

  private newOrderingSub(): void {
    this.$emit("newOrderingSub", "");
  }
}
